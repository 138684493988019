
function Year() {

        var d = new Date();
        var n = d.getFullYear();

        return String(n);
  
}
export default Year

